import tw, { css, styled } from "twin.macro"

import useLFL, { LFLContentTypes } from "lib/useLFL"
import { TextBlockImageStoryProps } from "components/dynamic"

import Section from "components/Section"
import Grid from "components/Grid"
import Column from "components/Column"
import Image from "components/Image"
import { IntroText, H3, H4, H6 } from "styles/Text"
import { breakpoint } from "styles/Variables"
import AudioPlayer from "components/AudioPlayer"
import Language from "components/Language"

// Styled components
const IllustrationStyled = styled.img(() => [
  tw`absolute right-0 top-16 lg:top-30`,
  css`
    width: 180px;

    ${breakpoint.lg`
      width: 220px;
    `}
  `,
])

// not sure how to achieve this with components/Image?
// docs aren't the greatest.
const ImageWrapper = styled.div(() => [
  tw`relative max-w-full w-full lg:-mt-16 z-0`,
  css`
    height: 375px;
    ${breakpoint.lg`
      height: 568px;
    `}
  `,
])

const TextBlockImageStory = ({
  Anchor,
  Heading,
  LFLStoryID,
  UpperTitle,
  Title,
  BodyIntro,
  Body,
  // PlayLink,
  ImageFocusHorizontal = "center",
  ImageFocusVertical = "center",
  ImageBlock,
  BackgroundImage,
  Illustration,
}: TextBlockImageStoryProps) => {
  const LFLData = useLFL(LFLStoryID, LFLContentTypes.STORIES)
  const LFLStorySection = LFLData?.sections?.[0]

  return (
    <div tw="relative bg-bunuba-light pt-7.5 lg:pt-24">
      {Illustration?.url && (
        <IllustrationStyled
          src={Illustration.url}
          alt={Illustration.alternativeText}
        />
      )}
      <Section anchor={Anchor} gridSize={{ lg: 5 / 6 }}>
        {Heading && (
          <Grid halign="center" tw="mb-3 lg:mb-20 z-10 relative">
            <Column size={{ sm: 2 / 3, md: 0.54 }}>
              <IntroText tw="text-center" as="h2">
                {Heading}
              </IntroText>
            </Column>
          </Grid>
        )}

        <Grid tw="pt-7.5" halign="center">
          {ImageBlock?.url && (
            <Column size={{ xs: 2 / 3, lg: 4 / 10 }}>
              <div tw="grid place-items-center shadow-2xl relative z-10">
                <Image
                  src={ImageBlock.url}
                  alt={ImageBlock.alternativeText}
                  width={386}
                  height={488}
                  objectFit="cover"
                  objectPosition={[
                    ImageFocusHorizontal,
                    ImageFocusVertical,
                  ].join(" ")}
                />
              </div>
            </Column>
          )}

          <Column
            size={{ lg: 6 / 10 }}
            tw="text-center mt-15 flex flex-col justify-center items-stretch lg:mt-0"
          >
            <div tw="lg:px-9">
              {UpperTitle && (
                <H6 as="div" tw="text-primary mb-5">
                  {UpperTitle}
                </H6>
              )}

              {(LFLStorySection?.text || Title) && (
                <H3 as="h2" tw="md:mt-8">
                  {LFLStorySection?.text || Title}
                  {LFLStorySection?.audio && (
                    <AudioPlayer
                      src={LFLStorySection.audio}
                      track={`/api/stories/${LFLData.id}/${LFLStorySection.id}/bck.vtt`}
                      tw="ml-2"
                    />
                  )}
                </H3>
              )}

              {(LFLStorySection?.text_english || BodyIntro) && (
                <H4 as="h3" tw="mt-1.5 md:mt-3.5" thin>
                  {LFLStorySection?.text_english || BodyIntro}
                </H4>
              )}

              {Body && (
                <div tw="text-dark-grey mt-5 md:mt-7.5">
                  <Language>{Body}</Language>
                </div>
              )}
            </div>
          </Column>
        </Grid>
      </Section>

      {BackgroundImage?.url && (
        <ImageWrapper>
          <div tw="absolute inset-0 bottom-auto h-50 bg-gradient-to-b from-bunuba-light z-10" />
          <Image
            layout="fill"
            objectFit="cover"
            objectPosition={[ImageFocusHorizontal, ImageFocusVertical].join(
              " "
            )}
            src={BackgroundImage.url}
            alt={BackgroundImage.alternativeText}
          />
        </ImageWrapper>
      )}
    </div>
  )
}

export default TextBlockImageStory
